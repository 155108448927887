<template>
    <div class="student-mobile-page">
        <div>
            <v-btn @click="back">
                Back
            </v-btn>
            <div class="student-filter-container">
                <div class="filter-search mr-4">
                    <v-text-field
                        v-model="filterForm.name"
                        label="Name"
                        prepend-icon="mdi-magnify"
                    >
                    </v-text-field>
                </div>
                <div class="d-flex d-flex-align-center">
                    <div class="filter-search mr-4">
                        <v-select
                            class="filter-search"
                            v-model="filterForm.branchId"
                            :items="branchList"
                            label="Branch"
                            item-text="key"
                            item-value="value"
                        ></v-select>
                    </div>
                    <div>
                        <v-btn
                            @click="getStudentList"
                            color="primary"
                            outlined
                        >
                            Search
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-data-table
                :headers="headers"
                :items="studentList"
                class="elevation-1"
            >
                <template v-slot:[`item.line`]="{ item }">
                    <v-img
                        class="pointer"
                        :src="require('../assets/line-icon.png')"
                        height="60"
                        width="60"
                        @click="openLineCodeModal(item)"
                    />
                </template>
            </v-data-table>
        </div>
        <line-code-generate
            ref="lineCodeModal"
            :studentId="selectStudentId"
        >

        </line-code-generate>
    </div>
</template>

<script>
import LineCodeGenerate from '../components/LineCodeGenerate'

export default {
    name: 'StudentsMobile',
    components: {
        LineCodeGenerate
    },
    data () {
        return {
            filterForm: {
                name: '',
                branchId: null
            },
            studentList: [],
            headers: [
                { text: 'Name', value: 'fullName' },
                { text: 'Nickname', value: 'nickname' },
                { text: 'Branch', value: 'branch' },
                { text: 'Line', value: 'line' }
            ],
            branchList: [],
            selectStudentId: null
        }
    },

    methods: {
        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchList = [...this.branchList, ...res.data.data]
                if (localStorage.getItem('branches')) {
                    this.filterForm.branchId = parseInt(localStorage.getItem('branches').split(',')[0])
                }
            }
        },
        async getStudentList () {
            const url = `students?name=${this.filterForm.name}&branch=${this.filterForm.branchId}`
            const res = await this.axios.get(url)
            if (res && res.data && res.data.status === 'ok') {
                this.studentList = res.data.data
            }
        },

        openLineCodeModal (item) {
            this.$refs.lineCodeModal.dialog = true
            this.selectStudentId = item.id
        },

        back () {
            this.$router.push({ path: '/students' })
        }
    },

    async mounted () {
        await this.getBranchOptions()
        await this.getStudentList()
    }
}
</script>

<style lang="scss" scoped>
.student-mobile-page {
    padding: 2rem;
}
</style>
